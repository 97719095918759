// PageOne.js
import React from 'react';
import { useAuth } from './AuthLocalStorage';

function PageOne() {
  const { telegramUser } = useAuth();
  console.log('PageOne - Telegram User:', telegramUser);

  return (
    <div>
      {telegramUser ? (
        <div>
          Welcome, {telegramUser.first_name}! This is the first awesome page.
          {/* Include content accessible to logged-in users */}
        </div>
      ) : (
        <div>
          <p>You need to log in to access this page.</p>
          {/* You can add a link to the login page or a login button here */}
        </div>
      )}
    </div>
  );
}

export default PageOne;
