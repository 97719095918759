// Page2.js
import React from "react";
import { useAuth } from "./AuthLocalStorage";

function PageTwo() {
  const { telegramUser } = useAuth();

  return (
    <div>
      {telegramUser ? (
        <div>
          Welcome, {telegramUser.first_name}! This is the second awesome page.
          {/* Include content accessible to logged-in users */}
        </div>
      ) : (
        <p>You need to log in to access this page.</p>
      )}
    </div>
  );
}

export default PageTwo;
