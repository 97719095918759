import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [telegramUser, setTelegramUser] = useState(null);

  // Function to persist user data in local storage
  const saveUserToLocalStorage = (user) => {
    localStorage.setItem("telegramUser", JSON.stringify(user));
  };

  // Function to retrieve user data from local storage
  const getUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem("telegramUser");
    return storedUser ? JSON.parse(storedUser) : null;
  };

  const login = (user) => {
    setTelegramUser(user);
    saveUserToLocalStorage(user);
  };

  const logout = () => {
    setTelegramUser(null);
    localStorage.removeItem("telegramUser");
  };

  // useEffect to log our current Telegram user and watch for changes
  useEffect(() => {
    const storedUser = getUserFromLocalStorage();
    if (storedUser) {
      setTelegramUser(storedUser);
    }
    //console.log("Current Telegram User:", telegramUser);
  }, [telegramUser]);

  return (
    <AuthContext.Provider value={{ telegramUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
