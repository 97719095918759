// Home.js
import React from "react";
import { useAuth } from "./AuthLocalStorage";
import TelegramLoginButton from "react-telegram-login";

function Home() {
  const { telegramUser, login } = useAuth();

  const handleTelegramResponse = (response) => {
    console.log("Telegram authentication response:", response);
    // Update the state with Telegram user data
    login(response);
  };

  return (
    <div>
      {telegramUser ? (
        <div>
          Welcome, {telegramUser.first_name}! This is the awesome home page.
          {/* Include content accessible to logged-in users */}
        </div>
      ) : (
        <div>
          <p>You need to log in to access this site.</p>
          <TelegramLoginButton
            dataOnauth={handleTelegramResponse}
            botName="ClickWizardBot"
          />
        </div>
      )}
    </div>
  );
}

export default Home;
