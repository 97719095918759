
// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthLocalStorage';
import Home from './Home';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import TelegramLoginButton from 'react-telegram-login';

function LoginButton() {
  const { telegramUser, login } = useAuth();

  const handleTelegramResponse = response => {
    // Update the state with Telegram user data
    login(response);
  };

  return (
    <div>
      {telegramUser ? (
        <p>Welcome, {telegramUser.first_name}!</p>
      ) : (
        <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="ClickWizardBot" />
      )}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/page1" element={<PageOne />} />
          <Route path="/page2" element={<PageTwo />} />
          {/* Add more routes as needed */}
          <Route path="/login" element={<LoginButton />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
